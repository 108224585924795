<template>
    <div class="answer">

        <van-pull-refresh v-model="loading" @refresh="refresh">
            <van-list class="keFuList" v-model="loading2" :finished="finished" finished-text="没有更多了" offset="50" :immediate-check="false" @load="loadMore" >
                <div v-for="(item,i) in daYiData" :key="i" class="card" @click="toSeeAnswer(item)">
                    <div class="top">
                        <van-image width="26px" height="24.4px" :src="iconAsk"/>
                        <div class="top_div">{{item.questionContent}}</div>
                    </div>
                    <div class="bottom">
                        <van-image width="26px" height="24.4px;" :src="iconAnswer"/>
                        <div v-if="item.answerContent == '' || item.answerContent == null" class="bottom_div">等待专家解答</div>
                        <div class="bottom_div mult_line_ellipsis" v-html="item.answerContent"></div>
                    </div>
                </div>
                <div style="height:30vh;"></div>
                
            </van-list>
        </van-pull-refresh>
        
    </div>
</template>

<script>
import {dayiList} from '@/api/selectClass.js'

export default {
    props:['coursePackageId','courseId','classHourId'],
    data() {
        return {
            iconAsk:require('@/assets/all_Icon/drawable-xxhdpi/icon_ask.png'),
            iconAnswer:require('@/assets/all_Icon/drawable-xxhdpi/icon_answer.png'),
            
            loading:false,
            loading2:false,
            finished:false,
            daYiData:[],
            count:0,
            page:0,

        }
    },
    created() {
        this.dayiListFn()
        
    },
    methods: {
        // 获取笔记列表
        async dayiListFn(){
            let data = new FormData()
            data.append('coursePackageId',this.coursePackageId)
            data.append('courseId',this.courseId)
            data.append('classHourId',this.classHourId)
            data.append('page',0)

            let res = await dayiList(data)
            console.log('答疑列表---------',res)
            if(res.data.code == 0) this.daYiData.push(...res.data.data)
            if(res.data.code == 0) this.count = res.data.data.length
            if(res.data.code != 0) this.$toast(res.data.msg)
            this.loading = false
            this.loading2 = false
        },
        // 下拉刷新
        refresh(){
            this.daYiData = []
            this.finished = false
            this.count = 0
            this.page = 0
            this.dayiListFn()
        },
        // 加载更多
        loadMore(){
            this.page ++
            console.log('通知消息加载更多----',this.keFuPage)
            if(this.daYiData.length >= this.count){ 
                return this.finished = true
            }
            this.dayiListFn()
        },
        toSeeAnswer(item){
            this.$router.push({
                path:'/classDaYi',
                query: {
                    id:item.id,
                    ask: item.questionContent,
                    answer: item.answerContent,
                }
            })
        }

    },
}
</script>

<style lang="less" scoped>
.answer {
    height: 100%;
    background-color: #f5f5f5;

    .card{
        margin-bottom: 14px;
        background-color: #fff;
        padding: 16px 18px;

        .top{
            display: flex;
            margin-bottom: 10px;
            align-items: center;
            width: 307px;
            .top_div{
                margin-left: 10px;
                font-size: 14px;
            }
        }
        .bottom{
            display: flex;
            align-items: center;
            width: 307px;
            .bottom_div{
                margin-left: 10px;
                max-width: 250px;
                font-size: 14px;
            }
        }
    }
    

}
</style>

