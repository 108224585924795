import { render, staticRenderFns } from "./videoPagePlan.vue?vue&type=template&id=c7c1cdc8&scoped=true&"
import script from "./videoPagePlan.vue?vue&type=script&lang=js&"
export * from "./videoPagePlan.vue?vue&type=script&lang=js&"
import style0 from "./videoPagePlan.vue?vue&type=style&index=0&id=c7c1cdc8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7c1cdc8",
  null
  
)

export default component.exports