<template>
    <div class="addNotePage">
        <div class="addTitle" @click="toAddNotePage">
            <van-image class="addTitle_img" width="26px" height="19.5px" :src="iconAdd"/>
            <span class="addTitle_sp">新增笔记</span>
        </div>

        <div class="card" v-for="(item,i) in noteData" :key="i">
            <div class="left">
                <div class="left_div1 ellipsis">{{item.content}}</div>
                <div class="left_div2">上架时间：{{item.intime}}</div>
            </div>
            <div @click="editNote(item)">
                <van-image width="26px" height="24.4px" :src="iconEdit"/>
            </div>
        </div>

    </div>
</template>

<script>
import {noteList} from '@/api/selectClass.js'

export default {
    props:['coursePackageId','courseId','classHourId'],
    data() {
        return {
            iconAdd:require('@/assets/all_Icon/drawable-xxhdpi/icon_add.png'),
            iconEdit:require('@/assets/all_Icon/drawable-xxhdpi/answer_item_edit.png'),
            
            noteData:[{
                content:'啦啦啦啦啦啦啦啦',
                time:'2022-09-09'
            }],
        }
    },
    created() {
        this.getNoteList()
        
    },
    methods: {
        // 获取笔记列表
        async getNoteList(){
            let data = new FormData()
            data.append('coursePackageId',this.coursePackageId)
            data.append('courseId',this.courseId)
            data.append('classHourId',this.classHourId)
            data.append('content',this.content)
            
            let res = await noteList(data)
            if(res.data.code == 0) this.noteData = res.data.data
            console.log('获取笔记------',this.noteData)
        },
        // 跳笔记页  新加笔记
        toAddNotePage(){
            this.$router.push({
                path:'/addNotePage',
                query:{
                    coursePackageId: this.coursePackageId,
                    courseId: this.courseId,
                    classHourId: this.classHourId,
                    from:'add'
                }
            })
        },
        // 跳笔记页  编辑已有笔记
        editNote(item){
            this.$router.push({
                path:'/addNotePage',
                query:{
                    coursePackageId: this.coursePackageId,
                    courseId: this.courseId,
                    classHourId: this.classHourId,
                    id: item.id,
                    from:'edit',
                }
            })
        }

    },
}
</script>

<style lang="less" scoped>
.addNotePage {
    background-color: #f5f5f5;

    .addTitle{
        margin-top: 10px;
        height: 28px;
        margin-bottom: 10px;

        .addTitle_sp{
            vertical-align: text-bottom;
            font-size: 15px;
            color: #666666;
            margin-left: 10px;
        }
    }
    
    .card{
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        margin-bottom: 14px;
        padding: 10px;
        border-radius: 5px;
        
        .left{
            font-size: 14px;
            color: #666666;

            .left_div1{
                margin-bottom: 10px;
                width: 270px;
            }
            .left_div2{
                font-size: 13px;
            }
        }
    }

}
</style>

