<template>
    <div class="videoPlay">
        <!-- 头部导航 -->
        <van-nav-bar class="navBar" fixed :placeholder="true" @click-left="$router.go(-1)">
            <template #left class="left">
                <van-icon name="arrow-left" size="22" color="#141414" />
            </template>
            <template #title>
                <span style="font-size:16px;font-weight:500;" :title="navTitle">{{navTitle}}</span>
            </template>
            <template #right>
                <van-icon size="22" name="wap-home-o" @click="$router.push('/')" />
            </template>
        </van-nav-bar>

        <!-- 视频播放 -->
        <van-sticky :offset-top="45">
            <div class="v_Box">
                <videoPlayer v-if="videoId" :vid="videoId" @planReportedData="planReportedData"/>
            </div>
        </van-sticky>

        <!-- 目录、讲义、下载 -->
        <van-sticky :offset-top="256">
            <videoMain ref="videoMainRef" v-if="coursePackageId" :coursePackageId="coursePackageId" :courseId="courseId" :classHourId="classHourId"  @playVideo="playVideo" />
        </van-sticky>

        <!-- 底部导航 -->
        <tabBar></tabBar>
    </div>
</template>

<script>
import videoPlayer from '@/components/videoPlayer'
import videoMain from '@/components/videoMain'
import tabBar from '@/components/tabBar' //底部导航 组件

import { packageDetail, courseware, planReportedData } from '@/api/selectClass.js'
import { coures } from '@/api/study_course.js'

export default {
    components:{ tabBar, videoPlayer, videoMain},
    data() {
        return {
            navTitle:'视频播放',
            active:0,
            videoId:'',
            // 套餐id:coursePackageId > 课程id:courseId > 章节id:chapterId

            detailData:{}, //套餐详情
            coursePackageId:'', //套餐id 31
            courseId:null, //课程id 7
            chapterId:null, //章节id 30
            classHourId:null,

            videoData:{}, //当前视频数据：点击某个视频后-由子组件传递上来
        }
    },
    created() {
        // console.log('视频页路径---------',this.$route.params)
        if(this.$route.params.coursePackageId) this.coursePackageId = this.$route.params.coursePackageId
        if(this.$route.params.courseId) this.courseId = this.$route.params.courseId
        if(this.$route.params.classHourId) this.classHourId = this.$route.params.classHourId

        this.packageDetailFun()
    },
    mounted(){
        coures()
    },
    methods: {
        // 获取详情
        packageDetailFun(){
            let data = new FormData()
            data.append('coursePackageId',this.coursePackageId)
            packageDetail(data).then(res=>{
                // console.log('选课中心-classCenter---',res)
                if(res.data.code == 0){
                    this.detailData = res.data.data
                    console.log('视频播放页-获取套餐详情---',this.detailData)

                    // 循环出当前课时详情 拿到videoId
                    res.data.data.courseScheduleList.forEach((item, i) => {
                        for (var j = 0; j < item.chapterList.length; j++) {
                            for (var k = 0; k < item.chapterList[j].classHourList.length; k++){
                                // 反推chapterId并获取课时详情
                                if(item.chapterList[j].classHourList[k].classHourId == this.classHourId){
                                    this.activeChapterId = item.chapterList[j].chapterId.toString()
                                    this.videoId = item.chapterList[j].classHourList[k].videoId
                                    this.navTitle = item.chapterList[j].classHourList[k].classHourName
                                    console.log('反推chapterId1-------',this.activeChapterId)
                                }

                            }
                        }
                    })
                }
            })
        },
        // 播放点击的视频
        playVideo(videoData){
            console.log('播放点击的视频--',videoData)
            this.navTitle = videoData.classHourName
            this.videoId = videoData.videoId
            this.classHourId = videoData.classHourId
            this.videoData = videoData
        },
        // 视频进度上报
        planReportedData(watchVideoTimeLength){
            if(watchVideoTimeLength <= 0) return
            let data = new FormData()
            data.append('coursePackageId',this.coursePackageId)
            data.append('courseId',this.courseId)
            data.append('classHourId',this.classHourId)
            data.append('videoPlan',watchVideoTimeLength) //进度/秒

            planReportedData(data).then(res =>{
                // this.$toast('视频进度上报')
                if(res.data.code == 0){
                    console.log('视频进度上报成功')
                }
            })
        }
    },
}
</script>

<style lang="less" scoped>
.videoPlay {
    background-color: #f5f5f5;

    .bgc{
        background-color: pink;
    }

    .v_Box{
        min-height: 210px;
    }

    .tabDiv{
        height:55vh;
        overflow: auto;
        padding: 10px 16px;
    }

}
</style>

