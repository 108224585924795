<template>
    <div class="videoMain">
        <!-- 目录、讲义、下载 -->
        <van-tabs class="vanTabs" v-model="active" swipeable animated color="#5D7DFF">

            <!-- 目录 -->
            <van-tab title="目录">
                <div class="tabDiv muLu">
                    <videoPagePlan v-if="detailData.coursePackageId" :detailData="detailData" :courseId="courseId" :classHourId="classHourId" @playVideo="playVideo"/>
                </div>
            </van-tab>

            <!-- 讲义 -->
            <van-tab title="讲义">
                <div class="tabDiv jyBox">
                    <jiangYi v-if="courseId" :courseId="courseId" />
                </div>
            </van-tab>

            <!-- 新增、编辑笔记 v-if="detailData.isFree == 1 && detailData.isBuy == 1"-->
            <van-tab  title="笔记" v-if="detailData.isFree == 1 && detailData.isBuy == 1">
                <div class="tabDiv jyBox">
                    <addNote v-if="courseId" :coursePackageId="coursePackageId" :courseId="courseId" :classHourId="classHourId" />
                </div>
            </van-tab>

            <!-- 答疑  v-if="detailData.isFree == 1 && detailData.isBuy == 1"-->
            <van-tab class="dayi" title="答疑" v-if="detailData.isFree == 1 && detailData.isBuy == 1">
                <div class="tabDiv daYiBox">
                    <answer v-if="courseId" :coursePackageId="coursePackageId" :courseId="courseId" :classHourId="classHourId"/>
                    <van-button class="btn" @click="toAnswerPage" type="primary" color="#5F7DFF" block>提问</van-button>
                </div>
            </van-tab>
        </van-tabs>

    </div>
</template>

<script>
import videoPlayer from '@/components/videoPlayer'
import videoPagePlan from '@/components/videoPagePlan'
import videoDownload from '@/components/videoDownload'
import jiangYi from '@/components/jiangYi'
import addNote from '@/components/addNote'
import answer from '@/components/answer'

import { packageDetail, courseware } from '@/api/selectClass.js'

export default {
    props:['coursePackageId','courseId','chapterId','classHourId'],
    components:{ videoPlayer, videoPagePlan, jiangYi, videoDownload, addNote, answer},
    data() {
        return {
            // 套餐id:coursePackageId > 课程id:courseId > 章节id:chapterId
            active:0,
            videoId:'',
            
            // coursePackageId:'', //套餐id 31
            detailData:{}, //套餐详情

            // courseId:'', //课程id 7
            // courseData:{}, //课程详情

            // chapterId:'', //章节id 30
            // chapterData:{}, //章节详情
        }
    },
    created() {

        if(this.coursePackageId) this.packageDetailFun()
    },
    methods: {
        // 获取详情
        packageDetailFun(){
            let data = new FormData()
            data.append('coursePackageId',this.coursePackageId)
            packageDetail(data).then(res=>{
                // console.log('选课中心-classCenter---',res)
                if(res.data.code == 0){
                    this.detailData = res.data.data
                    console.log('视频播放页-获取套餐详情---',this.detailData)

                    // 过滤出 课程详情
                    // res.data.data.courseScheduleList.forEach((item,i)=>{
                    //     if(item.courseId == this.courseId){
                    //         this.courseData = item
                    //         // for(var j = 0; j<item.chapterList.length; j++){
                    //         //     if( item.chapterList[j].chapterId == this.chapterId ){this.chapterData = item.chapterList[j]}
                    //         // }
                    //     }
                    // })
                }
            })
        },
        // 播放点击的视频
        playVideo(videoData){
            console.log('播放点击的视频--',videoData)
            this.videoId = videoData.videoId
            this.$emit('playVideo',videoData)
        },
        // 答疑 点击提问
        toAnswerPage(){
            this.$router.push({
                path:'/answerPage',
                query:{
                    coursePackageId: this.coursePackageId,
                    courseId: this.courseId,
                    classHourId: this.classHourId,
                }
            })
        }

    },
}
</script>

<style lang="less" scoped>
.videoMain {
    // min-height: 100vh;
    background-color: #f5f5f5;

    .tabDiv{
        height:53vh;
        overflow: auto;
        padding: 10px 16px;
    }
    .dayi{
        position: relative;

        .btn{
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            bottom: 40px;
            width: 90%;
            
        }
    }

}
</style>

