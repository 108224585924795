<template>
    <div class="classPlan">
        <!-- 课程 -->
        <van-collapse v-model="activeChapterId" accordion :border="false">
            <van-collapse-item class="chapterClass" v-for="(item,i2) in courseData.chapterList" :key="i2" :title="item.chapterName" :name="item.chapterId.toString()">
                <div v-for="(item2,i3) in item.classHourList" :key="i3" :class="activeClassHourId==item2.classHourId? 'bgc posId' : ''"> 
                    <div class="p3">
                        <div class="p3_p">
                            <div>
                                <div class="ellipsis p3_p_div1" :class="activeClassHourId==item2.classHourId? 'bgc' : ''">{{item2.classHourName}}</div>   
                                <div class="p3_p_div2" :class="activeClassHourId==item2.classHourId? 'bgc' : ''">{{item2.videoTimeLength}}分钟/已学{{item2.watchVideoTimeLength}}分钟</div>
                            </div>
                            <div v-if="detailData.isBuy==1 || detailData.isFree==0 || item2.isAudition == 1">
                                <img v-if="activeClassHourId == item2.classHourId" @click="toVideo(item2)" :src="iconSuspend" alt="#" class="imgStyle">
                                <img v-else @click="toVideo(item2)" :src="iconPaly" alt="#" class="imgStyle">
                            </div>
                            <img v-else @click="toVideo('noBuy')" :src="iconOnlock" alt="#" class="imgStyle">
                        </div>
                    </div>
                </div>
            </van-collapse-item>
            <div style="height:100px;"></div>
        </van-collapse>

        <!-- 旧版-已废弃 -->
        <!-- <div v-for="(item,i) in courseData.chapterList" :key="i">
            <div class="p2" @click.stop="onTitle1(i)">
                <p class="p2_p">
                    <span class="ellipsis">{{item.chapterName}}</span>
                    <van-icon :name="item.isShowSub == true? 'arrow-up' : 'arrow-down'" size="18px" />
                </p>
            </div>

            <div v-show="item.isShowSub" class="conten2">
                <div v-for="(item2,k) in item.classHourList" :key="k">
                    <div class="p3">
                        <div class="p3_p">
                            <div>
                                <div class="ellipsis p3_p_div1">{{item2.classHourName}}</div>
                                <div class="p3_p_div2">{{item2.videoTimeLength}}分钟/已学{{item2.watchVideoTimeLength}}分钟</div>
                            </div>
                            <div v-if="detailData.isBuy || detailData.isFree==0 || item2.isAudition == 1">
                                <img :ref="item2.videoId" @click="toVideo(item2)" :src="iconPaly" alt="#" class="imgStyle">
                            </div>
                            <img v-else @click="toVideo('noBuy')" :src="iconOnlock" alt="#" class="imgStyle">
                        </div>
                    </div>
                </div>
            </div>
        </div> -->


    </div>
</template>

<script>

export default {
    props: ['detailData', 'courseId','classHourId'],
    data() {
        return {
            activeCourseId:'', //90
            activeChapterId:'', //203
            activeClassHourId:'', //1483

            // 套餐id:coursePackageId > 课程id:courseId > 章节id:chapterId
            courseData: {}, //课程详情

            iconPaly: require('@/assets/all_Icon/drawable-xxhdpi/icon_paly.png'),//播放
            iconSuspend: require('@/assets/all_Icon/drawable-xxhdpi/icon_suspend.png'),//暂停
            iconOnlock: require('@/assets/all_Icon/drawable-xxhdpi/icon_lock.png'),//未解锁

        }
    },
    created() {
        let _this = this
        this.activeCourseId = this.courseId
        this.activeClassHourId = this.classHourId
        console.log('目录-参数---', this.classHourId)

        // 转化数据
        this.detailData.courseScheduleList.forEach((item, i) => {
            this.$set(item, "isShowSub", false)
            for (var j = 0; j < item.chapterList.length; j++) {
                this.$set(item.chapterList[j], "isShowSub", false)
                
                for (var k = 0; k < item.chapterList[j].classHourList.length; k++){
                    // 视频时长转换为分钟
                    item.chapterList[j].classHourList[k].videoTimeLength = (item.chapterList[j].classHourList[k].videoTimeLength / 60).toFixed(0)
                    item.chapterList[j].classHourList[k].watchVideoTimeLength = (item.chapterList[j].classHourList[k].watchVideoTimeLength / 60).toFixed(0)

                    // 反推chapterId
                    if(item.chapterList[j].classHourList[k].classHourId == _this.classHourId){
                        _this.activeChapterId = item.chapterList[j].chapterId.toString()
                        console.log('反推chapterId1-------',_this.activeChapterId)
                    }
                }
            }
            if (item.courseId == this.courseId) this.courseData = item
        })
        console.log('目录-课程详情222---', this.courseData)
        
    },
    mounted(){
        this.$nextTick(()=>{
            this.jumpPosition()
        })
    },
    methods: {
        // 跳视频播放页
        toVideo(item2) {
            if(item2 == 'noBuy') return this.$toast('未解锁该课程')
            this.activeClassHourId = item2.classHourId
            console.log('playing2-当前视频信息---',item2)
            this.$emit('playVideo',item2)
        },
        // 跳锚点位置
        jumpPosition(){
            let toElement = document.querySelector('.posId')
            toElement.scrollIntoView()
        }
    },
}
</script>

<style lang="less" scoped>
.classPlan {
    font-family: PingFangSC-Medium, PingFang SC;
    .bgc{
        background-color: #f5f5f5;
        color: blue;
    }

    .p2 {
        font-size: 14px;
        background-color: #fff;
        padding: 0 20px;

        .p2_p {
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #ccc;
        }
    }
    .conten2 {
        background-color: #fff;
    }

    .chapterClass{
        margin-bottom: 10px;
    }

    .p3 {
        font-size: 13px;
        padding: 0 14px;
        color: #2c3e50;

        .p3_p {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #ccc;

            .imgStyle{
                width:20px;height:20px;margin-right:10px
            }

            .p3_p_div1 {
                // margin-left: 18px;
                margin-bottom: 5px;
            }

            .p3_p_div2 {
                // margin-left: 18px;
                font-size: 11px;
            }
        }
    }
}
</style>
