<template>
    <div class="classPlan">
        <div v-for="(item,i) in courseData.chapterList" :key="i">

            <!-- 一级标题：备考指导班 -->
            <div class="p2" @click.stop="onTitle1(i)">
                <p class="p2_p">
                    <span class="ellipsis">{{item.chapterName}}</span>
                    <van-icon :name="item.isShowSub == true? 'arrow-up' : 'arrow-down'" size="18px" />
                </p>
            </div>

            <!-- 内容：标题-时长-下载按钮 -->
            <div v-show="item.isShowSub" class="conten2">
                <div v-for="(item2,k) in item.classHourList" :key="k">
                    <div class="p3">
                        <div class="p3_p">
                            <div>
                                <div class="p3_p_div1 ellipsis">{{item2.classHourName}}</div>
                                <div class="p3_p_div2">{{item2.videoTimeLength}}分钟/已学{{item2.watchVideoTimeLength}}分钟</div>
                            </div>
                            <div class="p3_p_div3" v-if="detailData.isBuy || detailData.isFree==0 || item2.isAudition == 1">
                                <img :ref="item2.videoId" @click="downloadCount(item2)" :src="iconDownload" alt="#" class="imgSize">
                                <div class="p3_p_div3_size">0M/{{item2.videoSize}}M</div>
                            </div>
                            
                            <img v-else @click="downloadCount('noBuy')" :src="iconDownload" alt="#" class="imgSize">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {downloadCount} from '@/api/selectClass.js'

export default {
    props: ['detailData', 'courseId'],
    data() {
        return {
            // 套餐id:coursePackageId > 课程id:courseId > 章节id:chapterId
            courseData: {}, //课程详情

            iconDownload: require('@/assets/all_Icon/drawable-xxhdpi/icon_download.png'),//下载
            // iconOnlock: require('@/assets/all_Icon/drawable-xxhdpi/icon_lock.png'),//未解锁
        }
    },
    created() {
        // 添加isShowSub属性，控制列表打开或关闭
        this.detailData.courseScheduleList.forEach((item, i) => {
            this.$set(item, "isShowSub", false)
            for (var j = 0; j < item.chapterList.length; j++) {
                this.$set(item.chapterList[j], "isShowSub", false)
                
                for (var k = 0; k < item.chapterList[j].classHourList.length; k++){
                    // 视频时长转换为分钟、视频大小转换、观看时长转换
                    // item.chapterList[j].classHourList[k].videoTimeLength = (item.chapterList[j].classHourList[k].videoTimeLength / 60).toFixed(0)
                    item.chapterList[j].classHourList[k].videoSize = (item.chapterList[j].classHourList[k].videoSize / 1024/1024).toFixed(0)
                    // item.chapterList[j].classHourList[k].watchVideoTimeLength = (item.chapterList[j].classHourList[k].watchVideoTimeLength / 60).toFixed(0)
                }
            }
            if (item.courseId == this.courseId) this.courseData = item
        })
        console.log('下载-课程详情---', this.courseData)
    },
    methods: {
        onTitle1(i) {
            this.courseData.chapterList[i].isShowSub = !this.courseData.chapterList[i].isShowSub
        },
        // 跳视频播放页
        downloadCount(item2) {
            this.$toast('下载视频')
            // let data = new FormData()
            // data.append('materialId',item2.classHourId)
            // downloadCount(data).then(res =>{
            //     console.log('下载---',res)
            // })
        }
    },
}
</script>

<style lang="less" scoped>
.classPlan {
    font-family: PingFangSC-Medium, PingFang SC;

    .p2 {
        font-size: 14px;
        background-color: #fff;
        padding: 0 20px;

        .p2_p {
            height: 44px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 0.5px solid #ccc;
        }
    }

    .conten2 {
        background-color: #fff;

        .p3 {
            font-size: 13px;
            padding: 0 20px;

            .p3_p {
                height: 60px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 0.5px solid #ccc;

                .imgSize{
                    width:20px;height:20px;margin-right:10px
                }

                .p3_p_div1 {
                    margin-left: 18px;
                    margin-bottom: 5px;
                }

                .p3_p_div2 {
                    margin-left: 18px;
                    font-size: 11px;
                }

                .p3_p_div3{
                    text-align: center;

                    .p3_p_div3_size{
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
</style>
